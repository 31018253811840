<template>
  <div
    v-if="structuredTable"
    class="my-20"
    :class="{
      'max-w-prose': options.width === 'narrow',
         'bk-paragraph-region-affected': options.isHidden,
    }"
  >
    <StructuredTable
      v-bind="structuredTable"
      :mobile-style="options.mobileStyle"
      :has-stripes="parentType !== 'accordion_element'"
      :heading-vertical="options.headingVertical"
      :equal-widths="options.equalWidths"
    />
  </div>
</template>

<script lang="ts" setup>
import { ParagraphTableMarkupFragment } from '#graphql-operations'

const { options, parentType, isEditing } = defineBlokkli({
  bundle: 'table_markup',
  globalOptions: ['isHidden'],
  options: {
    mobileStyle: {
      type: 'radios',
      label: 'Mobile Darstellung',
      default: 'vertical',
      options: {
        vertical: 'Vertikal',
        horizontal: 'Horizontal',
      },
    },
    width: {
      type: 'radios',
      label: 'Breite',
      default: 'narrow',
      options: {
        narrow: 'Schmal',
        full: 'Breit',
      },
    },
    headingVertical: {
      type: 'checkbox',
      label: 'Kopfzeile vertikal',
      default: false,
    },

    equalWidths: {
      type: 'checkbox',
      label: 'Spalten gleiche Breite',
      default: false,
    },
  },
  editor: {
    addBehaviour: 'no-form',
  },
})

defineProps<{
  structuredTable: ParagraphTableMarkupFragment['structuredTable']
}>()
</script>
