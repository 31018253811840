<template>
  <Accordion
    :title="title"
    :anchor="anchor"
    class="mt-20 md:mt-30 lg:mt-40"
    :always-open="isEditing"
    :class="{
      'bk-paragraph-region-affected': options.isHidden,
    }"
  >
    <template #title>
      <span v-blokkli-editable:field_title>{{ title }}</span>
    </template>
    <div>
      <BlokkliField
        :list="paragraphs"
        name="field_accordion_paragraph"
        :allowed-fragments="['force_page_break']"
      />
    </div>

    <div v-if="downloadUrl">
      <a class="button is-primary" :href="downloadUrl" target="_blank">
        {{ $texts('downloadAsPdf', 'Als PDF herunterladen') }}
      </a>
    </div>
  </Accordion>
</template>

<script lang="ts" setup>
import type { ParagraphAccordionElementFragment } from '#graphql-operations'

const { isEditing, options, uuid } = defineBlokkli({
  bundle: 'accordion_element',
  globalOptions: ['isHidden'],
  options: {
    showDownload: {
      type: 'checkbox',
      label: 'Download anzeigen',
      default: false,
    },
  },
})

const props = defineProps<{
  title: string
  anchor?: string
  parentId?: string
  paragraphs: ParagraphAccordionElementFragment['paragraphs']
}>()

const { $texts } = useEasyTexts()
const language = useCurrentLanguage()

const downloadUrl = computed(() => {
  if (!options.value.showDownload || !props.parentId) {
    return
  }

  return `/_print/${language.value}/paragraph/${props.parentId}/${uuid}`
})
</script>
