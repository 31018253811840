<template>
  <div class="w-full">
    <div class="relative">
      <ClientOnly>
        <div class="overflow-auto">
          <svg
            :width="svgWidth"
            :height="svgHeight"
            :viewBox="`0 0 ${svgWidth} ${svgHeight}`"
            :style="{
              width: svgWidth + 'px',
            }"
            class="w-full h-auto"
          >
            <!-- Horizontal dashed lines and Y labels -->
            <g v-for="(tick, index) in yTicks" :key="index">
              <line
                :x1="paddingLeft"
                :y1="tick.y"
                :x2="svgWidth - paddingRight"
                :y2="tick.y"
                class="stroke-gray-300"
                stroke-dasharray="5,5"
              />
              <text
                :x="paddingLeft - 10"
                :y="tick.y + 5"
                text-anchor="end"
                font-size="15"
                font-weight="400"
              >
                {{ tick.label }}
              </text>
            </g>

            <!-- Vertical dashed lines and X labels -->
            <g v-for="(year, index) in years" :key="index">
              <line
                :x1="paddingLeft + index * xScale"
                :y1="0"
                :x2="paddingLeft + index * xScale"
                :y2="svgHeight - paddingBottom + 22"
                class="stroke-gray-300"
                stroke-dasharray="5,5"
              />
              <text
                :x="paddingLeft + index * xScale + (index === 0 ? 5 : -5)"
                :y="svgHeight - paddingBottom + 20"
                :text-anchor="index === 0 ? 'start' : 'end'"
                font-size="15"
                font-weight="400"
              >
                {{ year }}
              </text>
            </g>

            <!-- X Axis -->
            <line
              :x1="paddingLeft"
              :y1="svgHeight - paddingBottom"
              :x2="svgWidth - paddingRight"
              :y2="svgHeight - paddingBottom"
              class="stroke-gray-500"
            />

            <!-- Y Axis -->
            <line
              :x1="paddingLeft"
              :y1="paddingTop"
              :x2="paddingLeft"
              :y2="svgHeight - paddingBottom"
              class="stroke-gray-500"
            />

            <!-- Lines and circles for data points -->
            <g v-for="(line, index) in linesData" :key="index">
              <polyline
                :points="line.points"
                fill="none"
                :stroke="line.color"
                stroke-width="2.5"
              />
              <g v-for="point in line.pointsArray">
                <circle
                  :cx="point.x"
                  :cy="point.y"
                  r="6"
                  :fill="line.color"
                  stroke="white"
                  stroke-width="3"
                  :style="{
                    transformOrigin: `${point.x}px ${point.y}px`,
                  }"
                  class="peer cursor-pointer transition hover:scale-150"
                  @mouseenter="activeValue = point"
                  @mouseleave="activeValue = null"
                />
              </g>
            </g>

            <!-- Axis Labels. -->
            <text
              v-if="axisLabels[0]"
              font-size="15"
              x="-5"
              :y="svgHeight / 2"
              style="writing-mode: tb"
              :transform-origin="`${0} ${svgHeight / 2}`"
              transform="rotate(180)"
              text-anchor="middle"
            >
              {{ axisLabels[0].toUpperCase() }}
            </text>

            <text
              v-if="axisLabels[1]"
              font-size="15"
              :x="svgWidth / 2"
              :y="svgHeight"
              text-anchor="middle"
            >
              {{ axisLabels[1].toUpperCase() }}
            </text>
          </svg>
        </div>
      </ClientOnly>
      <div
        v-if="activeValue"
        class="absolute pointer-events-none"
        :style="{
          top: activeValue.y - 10 + 'px',
          left: activeValue.x + 'px',
        }"
      >
        <div
          class="px-5 py-1 font-semibold text-white text-sm min-w-30 text-center absolute left-1/2 -translate-x-1/2 bottom-0"
          :style="{
            backgroundColor: activeValue.color,
          }"
        >
          {{ tooltipLabel }}
        </div>
      </div>
    </div>
    <ChartLegend :items="legendItems" :colors="colors" />
  </div>
</template>

<script setup lang="ts">
import {
  getColorPalette,
  getSaneNumber,
  type ChartColorPalette,
} from '~/helpers/charts'

const props = withDefaults(
  defineProps<{
    table: string[][]
    colorPalette: ChartColorPalette
    mutedCount: number
    isWide: boolean
    paddingTopMultiplicator: number
    paddingBottomMultiplicator: number
    paddingLeftOverride: number
    axisLabels: string[]
    valuePrefix?: string
    valueSuffix?: string
    decimalPoints: number
  }>(),
  {
    valuePrefix: '',
    valueSuffix: '',
  },
)

const { viewport, isPrint } = useViewport()

function getFormattedValue(value: number): string {
  const roundedNumber = value.toFixed(props.decimalPoints)
  return `${props.valuePrefix || ''}${roundedNumber}${props.valueSuffix || ''}`
}

const legendItems = computed(() => props.table[0].slice(1))

type DataPoint = {
  x: number
  y: number
  value: number
  color: string
}

type DataLine = {
  label: string
  points: string
  pointsArray: DataPoint[]
  color: string
}

const activeValue = ref<DataPoint | null>(null)

const tooltipLabel = computed(() => {
  if (!activeValue.value) {
    return
  }

  return getFormattedValue(activeValue.value.value)
})

function getColor(index: number) {
  if (index >= props.table[0].length - props.mutedCount) {
    return mutedColors.value[
      (index - (props.table[0].length - props.mutedCount)) %
        mutedColors.value.length
    ]
  }
  return colors.value[index % colors.value.length]
}

const colors = computed(() => getColorPalette(props.colorPalette))

const mutedColors = computed(() => [
  '#cccccc',
  '#bbbbbb',
  '#aaaaaa',
  '#999999',
  '#888888',
])

const paddingLeft = computed(() => {
  if (props.paddingLeftOverride !== 0) {
    return props.paddingLeftOverride
  }
  if (props.axisLabels[0]) {
    return 55
  }

  return 50
})
const paddingTop = ref(10)
const paddingRight = ref(5)
const paddingBottom = computed(() => {
  if (props.axisLabels[1]) {
    return 40
  }

  return 20
})

const svgWidth = computed(() => {
  if (isPrint.value) {
    return 770
  } else if (viewport.value === 'sm') {
    return Math.max(years.length * 15, 380)
  }
  return props.isWide ? 1080 : 770
})

const svgHeight = 400

const labels = props.table[0].slice(1)
const years = props.table.slice(1).map((row) => row[0])
const dataSets = props.table.slice(1).map((row) => row.slice(1).map(Number))

const flatData = dataSets.flat()
const maxDataValue = Math.max(...flatData)
const minDataValue = Math.min(...flatData)

const yMax = computed(
  () => maxDataValue + maxDataValue * props.paddingTopMultiplicator,
)
const yMin = computed(
  () => minDataValue - maxDataValue * props.paddingBottomMultiplicator,
)

const xScale = computed(
  () =>
    (svgWidth.value - paddingLeft.value - paddingRight.value) /
    (years.length - 1),
)
const yScale = computed(
  () =>
    (svgHeight - paddingTop.value - paddingBottom.value) /
    (yMax.value - yMin.value),
)

const yTicks = computed(() => {
  const range = getSaneNumber(yMax.value - yMin.value, false)
  const step = getSaneNumber(range / 8, true)
  const niceMin = Math.floor(yMin.value / step) * step
  const niceMax = Math.ceil(yMax.value / step) * step
  const tickCount = Math.round((niceMax - niceMin) / step) + 1

  const fixed = (step.toString().split('.')[1] || '').length

  return Array.from({ length: tickCount }, (_, i) => {
    const y =
      svgHeight -
      paddingBottom.value -
      (niceMin + i * step - yMin.value) * yScale.value

    const value = (niceMin + i * step).toFixed(fixed)
    const label = `${props.valuePrefix || ''}${value}${props.valueSuffix || ''}`
    return { y, label }
  }).filter((v) => {
    return v.y >= 0 && v.y < svgHeight - paddingBottom.value
  })
})

const linesData = computed<DataLine[]>(() => {
  return labels.map((label, idx) => {
    const color = getColor(idx)
    const pointsArray: DataPoint[] = dataSets.map((data, yearIdx) => {
      const x = paddingLeft.value + xScale.value * yearIdx
      const y =
        svgHeight -
        paddingBottom.value -
        (data[idx] - yMin.value) * yScale.value
      return { x, y, value: data[idx], color }
    })
    const points = pointsArray.map((point) => point.x + ',' + point.y).join(' ')
    return { label, points, pointsArray, color }
  })
})
</script>

<style lang="postcss"></style>
