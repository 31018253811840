<template>
  <table
    class="structured-table"
    :class="{
      'has-mobile-style': hasMobileStyle,
      'has-stripes': hasStripes,
      'has-heading-vertical': headingVertical,
      'has-equal-widths': equalWidths,
    }"
  >
    <caption
      v-if="caption"
      class="hyphens-auto text-balance"
      v-html="caption"
    />
    <thead v-if="thead.length">
      <tr v-for="(row, i) in thead" :key="'thead_' + i">
        <component
          :is="cell.tag"
          v-for="(cell, j) in row"
          :key="'thead_' + i + '_' + j"
          v-bind="cell.attributes"
        >
          <div
            :class="{ 'hyphens-auto': !headingVertical }"
            v-html="cell.content"
          />
        </component>
      </tr>
    </thead>

    <tbody v-if="tbody.length">
      <tr v-for="(row, i) in tbody" :key="'tbody_' + i">
        <component
          :is="cell.tag"
          v-for="(cell, j) in row"
          :key="'tbody_' + i + '_' + j"
          v-bind="getCellAttributes(cell, j)"
        >
          <div class="content" v-html="cell.content" />
        </component>
      </tr>
    </tbody>

    <tfoot v-if="tfoot.length">
      <tr v-for="(row, i) in tbody" :key="'tfoot_' + i">
        <component
          :is="cell.tag"
          v-for="(cell, j) in row"
          :key="'tfoot_' + i + '_' + j"
          v-bind="cell.attributes"
        >
          <div class="content" v-html="cell.content" />
        </component>
      </tr>
    </tfoot>
  </table>
</template>

<script lang="ts" setup>
import { StructuredTableCellFragment } from '#graphql-operations'

const props = defineProps<{
  mobileStyle: 'horizontal' | 'vertical'
  caption?: string
  thead: StructuredTableCellFragment[][]
  tbody: StructuredTableCellFragment[][]
  tfoot: StructuredTableCellFragment[][]
  hasStripes?: boolean
  headingVertical?: boolean
  equalWidths?: boolean
}>()

const hasMobileStyle = computed(() => {
  return props.mobileStyle === 'vertical'
})

function getCellAttributes(cell: StructuredTableCellFragment, index: number) {
  const attributes: Record<string, string> = cell.attributes || {}
  if (props.thead.length) {
    attributes['data-head-label'] = props.thead[0][index]?.content || ''
  }
  return attributes
}
</script>

<style lang="postcss">
.structured-table {
  @apply w-full text-sm lg:text-base;
  .content {
    @apply text-sm lg:text-base;
  }
  th,
  td {
    @apply text-left align-top;
    @apply p-10;
  }
  &.has-stripes {
    tr {
      @apply bg-gray-100;
    }
    tbody {
      tr {
        @apply bg-white even:bg-gray-100;
      }
    }
  }

  &.has-equal-widths {
    table-layout: fixed;
  }

  &.has-heading-vertical {
    thead {
      th {
        @apply align-bottom text-right;
        div {
          writing-mode: vertical-rl;
          max-height: 200px;
          @apply mx-auto;
        }
      }
    }
  }
  thead {
    tr {
      @apply uppercase;
    }
  }

  th {
    @apply font-medium leading-none;
  }

  tr {
    @apply border-b-3 border-b-gray-50;
  }

  caption {
    @apply text-left font-medium mb-10 md:text-xl;
  }

  @screen md {
    th,
    td {
      &:not(:last-child) {
        @apply border-r-3 border-r-gray-50;
      }
    }
  }

  td {
    &.highlight-berry {
      @apply bg-berry text-white uppercase font-medium;
    }

    &.highlight-berry-light {
      @apply bg-berry-light text-white uppercase font-medium;
    }

    &.highlight-orange {
      @apply bg-orange text-white uppercase font-medium;
    }

    &.highlight-orange-light {
      @apply bg-orange-light text-white uppercase font-medium;
    }

    &.highlight-gray {
      @apply bg-gray-100 uppercase font-medium;
    }
  }

  &.has-mobile-style {
    thead {
      @apply mobile-only:hidden;
    }
    tbody th,
    tbody tr,
    tbody td {
      @apply mobile-only:block;
    }

    td[data-head-label]:before {
      @apply mobile-only:content-[attr(data-head-label)] mobile-only:block mobile-only:text-xs mobile-only:font-medium mobile-only:mb-5;
    }

    td {
      @apply mobile-only:px-0;
      p {
        @apply not-last:mb-5;
      }
    }
  }
}
</style>
